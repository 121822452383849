import React, { useEffect, useState } from 'react';
import teamImg91 from '../../assets/images/Homeimages/team_def.png';

import '../../assets/bootstrap/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import CryptoJS from 'crypto-js';




const TeamSection = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [data, setData] = useState([]);




const encryptToken = (token, key, iv) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const ivHex = CryptoJS.enc.Utf8.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
    return encrypted.toString();
  };
useEffect(() => {
    const key = '8347383272422213';
    const iv = '1234567890123456';
    const token = 'Pass.lpc#webitaltech';

    const encryptedToken = encryptToken(token, key, iv);
    const fetchData = async () => {
      try {
        const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getTeamMembers`,
          {
            method: 'GET',
            headers: {
              Authorization: encryptedToken,
              'Content-Type': 'application/json',
            },
            referrerPolicy: 'no-referrer',
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const resp = await response.json();
        if (resp.status === 'error') {
          console.error('Error:', resp.message);
        } else {
          setData(resp.data.data);
 

          // console.log(resp);
          
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);
    return (
        <section className="team-main-section w-100 float-left padding-top padding-bottom light-bg">
        <div className="container">
            <div className="generic-title text-center">
                <span className="small-text" data-aos="fade-up" data-aos-duration="700">Board Members</span>
                <h2 className="mb-0" data-aos="fade-up" data-aos-duration="700">Our Board Members Team</h2>
            </div>

            <div className="team-inner-section-board">
            <div className="row">   
              {data?.map((member, index) => {
                const modalId = `exampleModal${index}`;
                return (
                  <React.Fragment key={index}>
                    <div
                      className="col-lg-3 member-con text-center"
                      data-aos="fade-up"
                      data-aos-duration="700"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      data-bs-toggle="modal"
                      data-bs-target={`#${modalId}`}
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <div className="member-img-details position-relative">
                        <figure style={{ position: "relative", margin: 0 }}>
                          <img 
                            src={`${member.image ? `https://localprimarycare.co.uk/public/images/team/${member.image}` : teamImg91}`} 
                            alt={member.username || 'team member image'} 
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "10px"
                            }}
                          />
                          {/* Overlay that appears on hover */}
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark transparent overlay
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              opacity: hoveredIndex === index ? 1 : 0, // Conditionally show overlay on hover
                              transition: "opacity 0.3s ease", // Smooth transition
                              borderRadius: "10px",
                            }}
                          >
                            <p style={{ margin: 0, fontSize: "16px", fontWeight: "bold" }}>
                              Click to preview details
                            </p>
                          </div>
                        </figure>
                        <br /><br />
                        <h4 data-aos="fade-up" data-aos-duration="700">{member.username}</h4>
                        <span className="d-block" data-aos="fade-up" data-aos-duration="700">{member.position}</span>
                      </div>
                    </div>
                    <Modal title={member.username} desc={member.description} modalId={modalId} imgsrc={member.image} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
    </section>
    );
};

export default TeamSection;
const Modal = ({ title, desc, modalId, imgsrc }) => {
    return (
        <div
            className="modal fade"
            id={modalId}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" style={{marginLeft: "auto", marginRight: "auto", fontWeight: "bold"}} id="exampleModalLabel">
                            {title}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ border: "none", background: "none", fontSize: "19px", cursor: "pointer", color: "red" }}
                        >&#10006;</button>
                    </div>
                    <div style={{marginLeft: "auto" , marginRight: "auto"}}>
                        <img  src={`${imgsrc ? `https://localprimarycare.co.uk/public/images/team/${imgsrc}` : teamImg91}`} className='mt-4' width={"180"} style={{borderRadius: "10%"}} alt={title || 'Team Member Image'} srcSet="" />
                    </div>
                    <div className="modal-body p-4" style={{color: "black"}} dangerouslySetInnerHTML={{ __html: desc}}>

                    </div>
                </div>
            </div>
        </div>
    );
};

