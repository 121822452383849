import React, { useEffect, useState } from 'react'
import aboutUsImage from '../assets/images/Homeimages/about-us-about-img.jpg';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import '../assets/css/custom-style.css';
import '../assets/css/responsive.css';
import '../assets/css/special-classes.css';
import { Link } from 'react-router-dom';
import processLeftImg from '../assets/images/Homeimages/1.png';
import processLeftImg1 from '../assets/images/Homeimages/2.png';
import CryptoJS from 'crypto-js';
import { Helmet } from 'react-helmet-async';


const AboutUs = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [bannerData, setbannerData] = useState([]);


  const encryptToken = (token, key, iv) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const ivHex = CryptoJS.enc.Utf8.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
    return encrypted.toString();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const key = '8347383272422213';
    const iv = '1234567890123456';
    const token = 'Pass.lpc#webitaltech';

    const encryptedToken = encryptToken(token, key, iv);
    const fetchData = async () => {
      try {
        const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getContentSections`,
          {
            method: 'GET',
            headers: {
              Authorization: encryptedToken,
              'Content-Type': 'application/json',
            },
            referrerPolicy: 'no-referrer',
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const resp = await response.json();
        if (resp.status === 'error') {
          console.error('Error:', resp.message);
        } else {
          setData(resp.data.data[0]);
          setData2(resp.data.data[1]);
          setData3(resp.data.data[2]);

          // console.log(resp);

        }
      } catch (err) {
        console.log(err.message);
      } finally {
        // setLoading(false);
      }
    };
    const fetchDataforbanner = async () => {
      try {
        const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getSubBanners`,
          {
            method: 'GET',
            headers: {
              Authorization: encryptedToken,
              'Content-Type': 'application/json',
            },
            referrerPolicy: 'no-referrer',
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const resp = await response.json();
        if (resp.status === 'error') {
          console.error('Error:', resp.message);
        } else {
          setbannerData(resp.data.data[2]);


          // console.log(resp);

        }
      } catch (err) {
        console.log(err.message);
      } finally {
        // setLoading(false);
      }
    };
    fetchDataforbanner();
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us | Local Primary Care</title>
        <meta name="description" content="Learn about Local Primary Care's mission, values, and the experienced healthcare professionals committed to providing exceptional medical services." />
        <meta name="keywords" content="local primary care, lpc federate, about local primary care, healthcare mission, medical values, experienced healthcare professionals" />
        <meta name="author" content="Local Primary Care Team" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://localprimarycare.co.uk/about-us" />
        <meta property="og:title" content="About Us | Local Primary Care" />
        <meta property="og:description" content="Learn about Local Primary Care's mission, values, and the experienced healthcare professionals committed to providing exceptional medical services." />
        <meta property="og:url" content="https://localprimarycare.co.uk/about-us" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* BANNER SECTION START */}
      <section className="sub-banner-section w-100 float-left d-flex align-items-center">
        <div className="container">
          <div className="sub-banner-inner-con text-center">
            <h1 data-aos="fade-up" data-aos-duration="700">{bannerData.title}</h1>
            {/* <p data-aos="fade-up" data-aos-duration="700">Roidunt eget semper nec ruam sed hendrerit morbi aeu
                            feliseao augue <br>
                            pellentesue veniam morbi acer.</p> */}
            <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration="700">
              <ol className="breadcrumb d-inline-block mb-0">
                <li className="breadcrumb-item d-inline-block"><Link to={"/"}>Home</Link></li>
                <li className="breadcrumb-item active text-white d-inline-block" aria-current="page">{bannerData.title}</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      {/* BANNER SECTION END */}

      {/* ABOUT SECTION START */}
      <section className="about-main-section about-us-about padding-top padding-bottom w-100 float-left position-relative">
        <div className="container position-relative">
          <div className="process-inner-con">
            <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
              <figure className="mb-0" data-aos="fade-up" data-aos-duration="700">
                <img src={aboutUsImage} alt="about-us-about-img" />
              </figure>
            </div>
            <div className="about-txt-con">
              <div className="generic-title" data-aos="fade-up" data-aos-duration="700">
                <span className="small-text">{data?.section}</span>
                <h2 className="mb-0">{data?.heading}</h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: data?.description }}>

              </div>
            </div>
          </div>
          {/* <div className="about-special-txt" data-aos="fade-up" data-aos-duration="700">
                        <figure>
                            <img src="assets/images/about-quote-img.png" alt="about-quote-img" />
                        </figure>
                        <h4>Ignissimos ducimus aui
                            aet quas molestias exceuri
                            recusandae itarue.</h4>
                    </div> */}
        </div>
      </section>
      {/* ABOUT SECTION END */}
      <section id='contact' className="process-main-section w-100 float-left padding-bottom contact-main-sec">
        <div className="container">
          <div className="generic-title text-center">
            <h2 data-aos="fade-up" data-aos-duration="700">{data2?.section}</h2>
          </div>
          <div className="process-inner-con">
            <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
              <figure className="mb-0">
                <img src={processLeftImg} alt="process-left-img" />
              </figure>
            </div>
            <div className="process-right-sec mt-5">
              <div className="generic-title">
                <h4 data-aos="fade-up" data-aos-duration="700">{data2?.heading}</h4>
              </div>
              <div dangerouslySetInnerHTML={{ __html: data2?.description }} />

            </div>

          </div>
        </div>
      </section>
      {/* <section className="team-main-section w-100 float-left padding-topp padding-bottomm">
            <div className="container">
    <div className="generic-title text-center">
        <h2 className="mb-0"></h2>
    </div>
    <p className='text-center'></p>
    <ul style={{ listStyleType: 'none', paddingLeft: '0' }} className='text-center'>
        <li style={{ marginBottom: '10px' }}>
            
        </li>
        <li style={{ marginBottom: '10px' }}>
            
        </li>
        <li style={{ marginBottom: '10px' }}>
           
        </li>
        <li style={{ marginBottom: '10px' }}>
            
        </li>
        <li style={{ marginBottom: '10px' }}>
         
        </li>
    </ul>
</div>

            </section> */}

      <section id='contact' className="process-main-section w-100 float-left padding-bottom contact-main-sec">
        <div className="container">
          <div className="generic-title text-center">
            <h2 data-aos="fade-up" data-aos-duration="700">{data3?.section}</h2>
          </div>
          <div className="process-inner-con">

            <div className="process-right-sec mt-4">
              <div className="generic-title">
                <h4 data-aos="fade-up" data-aos-duration="700"> {data3?.heading}</h4>
              </div>
              <div dangerouslySetInnerHTML={{ __html: data3?.description }} />

            </div>
            <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
              <figure className="mb-0">
                <img src={processLeftImg1} alt="process-left-img" />
              </figure>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default AboutUs