// import React from 'react'
// import LpcFerderate from '../Components/LpcFederate'

// const LpcFerderatee = () => {
//   return (
//     <>
//     </>
//   )
// }

// export default LpcFerderatee


import React from 'react'
import Header from '../Components/Header'
import BannerSection from '../Components/Banner'

import CounterSection from '../Components/counterSection'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import ProcessContSection from '../Components/formContcomp'
import TeamSection from '../Components/teamSection'
import TestimonialSection from '../Components/testiminials'
import FooterSection from '../Components/Footer'
import LpcFerderate from '../Components/LpcFederate'
import ServiceSectionfed from '../Components/servicefederate'
import BannerSectionfed from '../Components/Bannerfed'
import { Helmet } from 'react-helmet-async'


const LpcFerderatee = () => {
  return (
    <>
      <Helmet>
        <title>LPC Federate | Local Primary Care</title>
        <meta name="description" content="Learn about LPC Federate, our initiative to enhance healthcare collaboration, resources, and services for better patient outcomes." />
        <meta name="keywords" content="local primary care, lpc federate, healthcare collaboration, healthcare resources, enhanced patient care, local primary care initiative" />
        <meta name="author" content="Local Primary Care Team" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://localprimarycare.co.uk/lpcfederate" />
        <meta property="og:title" content="LPC Federate | Local Primary Care" />
        <meta property="og:description" content="Learn about LPC Federate, our initiative to enhance healthcare collaboration, resources, and services for better patient outcomes." />
        <meta property="og:url" content="https://localprimarycare.co.uk/lpcfederate" />
        <meta property="og:type" content="website" />
      </Helmet>
      <BannerSectionfed />
      <LpcFerderate />

      <ServiceSectionfed />
      {/* <CounterSection/> */}
      {/* <ProcessContSection/> */}
      {/* <TeamSection/> */}
      {/* <TestimonialSection/> */}
    </>
  )
}

export default LpcFerderatee