import React from 'react'
import Header from '../Components/Header'
import BannerSection from '../Components/Banner'
import ServiceSection from '../Components/Services'
import CounterSection from '../Components/counterSection'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import ProcessContSection from '../Components/formContcomp'
import TeamSection from '../Components/teamSection'
import TestimonialSection from '../Components/testiminials'
import FooterSection from '../Components/Footer'
import video from '../assets/BannerBgVideo/bg.webm';
import { Helmet } from 'react-helmet-async';
const Home = () => {
  return (
    <>
    <Helmet>
      <title>Local Primary Care | Comprehensive Healthcare Services in the UK</title>
      <meta name="description" content="Discover quality primary care services at Local Primary Care. Our dedicated team offers personalized healthcare solutions to meet your needs." />
      <meta name="keywords" content="local primary care, lpc federate, primary care, healthcare services, medical consultations, preventive care, personalized healthcare" />
      <meta name="author" content="Local Primary Care Team" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://localprimarycare.co.uk/" />
      <meta property="og:title" content="Local Primary Care | Comprehensive Healthcare Services in the UK" />
      <meta property="og:description" content="Discover quality primary care services at Local Primary Care. Our dedicated team offers personalized healthcare solutions to meet your needs." />
      <meta property="og:url" content="https://localprimarycare.co.uk/" />
      <meta property="og:type" content="website" />
    </Helmet>
    <BannerSection video={video}/>
    <ServiceSection />
    <CounterSection />  
    <ProcessContSection />
    <TeamSection />
    <TestimonialSection />
    </>
  )
}

export default Home