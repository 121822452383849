import React, { useEffect, useState } from 'react'
import teamImg1 from '../assets/images/Homeimages/team-img1.jpeg';
import teamImg2 from '../assets/images/Homeimages/team-img2.jpeg';
import teamImg3 from '../assets/images/Homeimages/team-img3.jpeg';
import teamImg4 from '../assets/images/Homeimages/team-img4.jpeg';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import '../assets/css/custom-style.css';
import '../assets/css/responsive.css';
import '../assets/css/special-classes.css';
import ListServiceSection from '../Components/servicesList';
import processLeftImg from '../assets/images/process-left-img.jpg';
import processIcon1 from '../assets/images/process-icon1.png';
import processIcon2 from '../assets/images/process-icon2.png';
import processIcon3 from '../assets/images/process-icon3.png';
import TestimonialSection from '../Components/testiminials';
import BlogSection from '../Components/BlogsSlider';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { Helmet } from 'react-helmet-async';

const AllServices = () => {
  const [bannerData, setbannerData] = useState([]);


  const encryptToken = (token, key, iv) => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const ivHex = CryptoJS.enc.Utf8.parse(iv);
      const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
      return encrypted.toString();
    };
  useEffect(() => {
      const key = '8347383272422213';
      const iv = '1234567890123456';
      const token = 'Pass.lpc#webitaltech';
  
      const encryptedToken = encryptToken(token, key, iv);
      const fetchDataforbanner = async () => {
        try {
          const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getSubBanners`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setbannerData(resp.data.data[3]);


            // console.log(resp);
            
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
      fetchDataforbanner();
    }, []);
  return (
    <>
    <Helmet>
      <title>Our Services | Local Primary Care</title>
      <meta name="description" content="Explore the range of healthcare services provided by Local Primary Care, including general consultations, preventive care, and chronic disease management." />
      <meta name="keywords" content="local primary care, lpc federate, healthcare services, medical services, consultations, preventive care, chronic disease management" />
      <meta name="author" content="Local Primary Care Team" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://localprimarycare.co.uk/services" />
      <meta property="og:title" content="Our Services | Local Primary Care" />
      <meta property="og:description" content="Explore the range of healthcare services provided by Local Primary Care, including general consultations, preventive care, and chronic disease management." />
      <meta property="og:url" content="https://localprimarycare.co.uk/services" />
      <meta property="og:type" content="website" />
    </Helmet>
  <section className="service-banner sub-banner-section w-100 float-left d-flex align-items-center">
    <div className="container">
      <div className="sub-banner-inner-con text-center">
        <h1 data-aos="fade-up" data-aos-duration={700}>
        {bannerData?.title}
        </h1>
        <p data-aos="fade-up" data-aos-duration={700} dangerouslySetInnerHTML={{ __html: bannerData?.text}}>
   
   </p>
        <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration={700}>
          <ol className="breadcrumb d-inline-block mb-0">
            <li className="breadcrumb-item d-inline-block">
              <Link to={"/"}>Home</Link>
            </li>
            <li
              className="breadcrumb-item active text-white d-inline-block"
              aria-current="page"
            >
             {bannerData?.title}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  {/* BANNER SECTION END */}

<ListServiceSection/>

{/* <section className="process-main-section w-100 float-left padding-top padding-bottom light-bg">
      <div className="container">
        <div className="process-inner-con">
          <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
            <figure className="mb-0">
              <img src={processLeftImg} alt="process-left-img" />
            </figure>
          </div>
          <div className="process-right-sec">
            <div className="generic-title">
              <span className="small-text" data-aos="fade-up" data-aos-duration="700">How it Works</span>
              <h2 data-aos="fade-up" data-aos-duration="700">Professional Psychology Therapy You Can Choose</h2>
            </div>
            <ul className="mb-0 list-unstyled" data-aos="fade-up" data-aos-duration="700">
              <li>
                <div className="process-img-sec">
                  <figure>
                    <img src={processIcon1} alt="process-icon1" />
                  </figure>
                </div>
                <div className="process-text-con">
                  <h4>Talk to Us First</h4>
                  <p>Nuis aute irure dolor reprehenderit in volutae velit esse fugiat.</p>
                </div>
              </li>
              <li>
                <div className="process-img-sec">
                  <figure>
                    <img src={processIcon2} alt="process-icon2" />
                  </figure>
                </div>
                <div className="process-text-con">
                  <h4>Book an Appointment</h4>
                  <p>Blandit fauce bus perce viverra sem rutrum aeu vulputate came.</p>
                </div>
              </li>
              <li>
                <div className="process-img-sec">
                  <figure>
                    <img src={processIcon3} alt="process-icon3" />
                  </figure>
                </div>
                <div className="process-text-con">
                  <h4>Come Sit With Us</h4>
                  <p>Maiores alias consequatur aut perferendis dolor reus asperiores repellat.</p>
                </div>
              </li>
            </ul>
            <div className="generic-btn">
              <Link to={"/#contact"}>Book Appointment</Link>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    <TestimonialSection />
    
    </>
  )
}

export default AllServices