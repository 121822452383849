import React, { useEffect } from 'react'
import BlogSection from '../Components/BlogsSlider'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const Blogs = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Helmet>
      <title>Health Insights Blog | Local Primary Care</title>
      <meta name="description" content="Stay informed with the latest health tips, news, and articles from the experts at Local Primary Care. Visit our blog for valuable health insights." />
      <meta name="keywords" content="local primary care, lpc federate, health blog, medical tips, health insights, healthcare articles" />
      <meta name="author" content="Local Primary Care Team" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://localprimarycare.co.uk/blog" />
      <meta property="og:title" content="Health Insights Blog | Local Primary Care" />
      <meta property="og:description" content="Stay informed with the latest health tips, news, and articles from the experts at Local Primary Care. Visit our blog for valuable health insights." />
      <meta property="og:url" content="https://localprimarycare.co.uk/blog" />
      <meta property="og:type" content="website" />
    </Helmet>
      <section className="service-banner sub-banner-section w-100 float-left d-flex align-items-center mb-5 ">
    <div className="container">
      <div className="sub-banner-inner-con text-center">
        <h1 data-aos="fade-up" data-aos-duration={700}>
          Blogs Coming Soon...
        </h1>
        {/* <p data-aos="fade-up" data-aos-duration={700}>
          Noidunt eget semper nec ruam sed hendrerit morbi aeu feliseao augue{" "}
          <br />
          pellentesue veniam morbi acer.
        </p> */}
        <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration={700}>
          <ol className="breadcrumb d-inline-block mb-0">
            <li className="breadcrumb-item d-inline-block">
              <Link to={"/"}>Home</Link>
            </li>
            <li
              className="breadcrumb-item active text-white d-inline-block"
              aria-current="page"
            >
           Blogs
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  {/* BANNER SECTION END */}
      {/* <BlogSection/> */}
    </>
  )
}

export default Blogs
